import {classes, vars} from './bymo-page-style.st.css';
import {
    doppeColorPaletteResolveColor,
    doppePageFontEnsureSupportedFontSpec,
    DoppePaletteColorType
} from '../../doppe-sdk';
import {
    ArrayValues,
    assertNotNullable,
    colorBlend,
    colorGetRgba,
    colorResolveLegibleForegroundColor,
    ColorValue,
    colorWhiten,
    cssResolveRgbColorComponentsForFunctionalNotationFormat,
    enumGetValues,
    evaluateFunction,
    objectCreateFromEntries,
    objectCreateFromKeys,
    objectPick,
    referencesEqualityComparer,
    stringIsNotNullableOrWhiteSpace,
    Values
} from '@wix/devzai-utils-common';
import {useLayoutBinding, useRootWindow} from '@wix/devzai-utils-react';
import React, {useCallback, useContext, useMemo} from 'react';
import {BymoPageProps, doppeHideableValueIsVisible} from '../../client-server-common';

const BymoPageStyleVarsNamesWithoutNamespace = [
    'bymoPageContentWidthInWideLayout',
    'actionWidgetRoundness',
    'mainColor',
    'mainColor10',
    'backgroundColor',
    'pageContentInnerBackground',
    'textLegibleBackgroundColor',
    'whitenBackgroundColor',
    'whitenBackgroundColorLegibleText',
    'backgroundColorRgb',
    'mainTextColor',
    'skipButtonBackgroundColor',
    'skipButtonTextColor',
    'separator',
    'actionButtonTextColor',
    'actionButtonTextColor80',
    'widgetBackgroundColor',
    'widgetInnerSeparator',
    'widgetInnerBackground',
    'widgetMainColor',
    'widgetMainColor60',
    'widgetMainColor80',
    'widgetMainColorLegibleForeground',
    'widgetTextColor',
    'widgetTextColor60',
    'widgetTextColor80',
    'inputBackgroundColor',
    'inputBorderColor',
    'inputTextColor',
    'inputPlaceholderColor',
    'pageContentErrorColor',
    'widgetErrorColor',
    'doppeViewerColorPopupError',
    'pageContentSuccessColor',
    'widgetSuccessColor',
    'doppeViewerColorPopupSuccess',
    'topLevelInputBackgroundColor',
    'topLevelInputTextColor',
    'topLevelInputPlaceholderColor',
    'textFontFamily',
    'textFontWeight',
    'headingsFontWeight',
    'doppeViewerColorPopupBackground',
    'doppeViewerColorPopupInnerBackground',
    'doppeViewerColorPopupText',
    'doppeViewerColorPopupTextSecondary',
    'doppeViewerColorPopupMain',
    'doppeViewerColorPopupMain30',
    'doppeViewerColorPopupMain10',
    'doppeViewerColorPopupMainLegibleForeground',
    'doppeViewerColorPopupMain30LegibleForeground',
    'doppeViewerColorPopupMain10LegibleForeground',
    'doppeViewerColorPopupInnerSeparator',
    'doppeViewerColorPopupInputBackgroundColor',
    'doppeViewerColorPopupInputBorderColor',
    'doppeViewerColorPopupInputTextColor',
    'doppeViewerColorPopupInputPlaceholderColor',
] as const;

export const BymoPageStyleVarsNames = objectCreateFromEntries(BymoPageStyleVarsNamesWithoutNamespace.map(name => ([name, name]))) as {
    [name in ArrayValues<typeof BymoPageStyleVarsNamesWithoutNamespace>]: name;
};

export type BymoPageStyleVarsNames = Values<typeof BymoPageStyleVarsNames>;

const BymoPageStyleScopeVarsNamesWithoutNamespace = [
    'bymoPageStyleScopeColorInnerBackground',
    'bymoPageStyleScopeColorText',
    'bymoPageStyleScopeMainColor',
    'bymoPageStyleScopeBackgroundColor',
    'errorColor',
    'successColor',
    // 'bymoPageStyleScopeMainColorLegibleForeground',
    'bymoPageStyleScopeInputBackgroundColor',
    // 'bymoPageStyleScopeInputBorderColor',
    // 'bymoPageStyleScopeInputTextColor',
    // 'bymoPageStyleScopeInputPlaceholderColor',
] as const;

export const BymoPageStyleScopeColors = {
    InnerBackgroundColor: 'bymoPageStyleScopeColorInnerBackground',
    TextColor: 'bymoPageStyleScopeColorText',
    MainColor: 'bymoPageStyleScopeMainColor',
    BackgroundColor: 'bymoPageStyleScopeBackgroundColor',
    ErrorTextColor: 'errorColor',
    SuccessTextColor: 'successColor',
    InputFieldBackgroundColor: 'bymoPageStyleScopeInputBackgroundColor'
} as const;

export type BymoPageStyleScopeColors = Values<typeof BymoPageStyleScopeColors>;

export const BymoPageStyleScopeVarsNames = objectCreateFromKeys(enumGetValues(BymoPageStyleScopeColors), key => key) satisfies Record<ArrayValues<typeof BymoPageStyleScopeVarsNamesWithoutNamespace>, unknown> as {
    [name in ArrayValues<typeof BymoPageStyleScopeVarsNamesWithoutNamespace>]: name;
};

export type BymoPageStyleScopeVarsNames = Values<typeof BymoPageStyleScopeVarsNames>;

export const bymoPageStyleVars = objectPick(vars, BymoPageStyleVarsNamesWithoutNamespace);

export function bymoPageStyleResolveVars(
    bymoPageProps: Pick<BymoPageProps, 'colorPalette' | 'textFontFamily' | 'lang' | 'devCustomPageContentWidthInWideLayout'>
) : Record<string, string> {
    const {
        colorPalette,
        textFontFamily,
        lang,
        devCustomPageContentWidthInWideLayout
    } = bymoPageProps;

    const darkErrorColor = '#ad2525';
    const brightErrorColor = '#eeaaaa';
    const darkSuccessColor = '#388E3C';
    const brightSuccessColor = '#CCE7CC';

    const resolveInnerBackgroundColor = (backgroundColor: ColorValue) => {
        // return colorResolveLegibleForegroundColor(
        //     backgroundColor,
        //     assertNotNullable(colorGetRgba('#000000', 0.05)),
        //     assertNotNullable(colorGetRgba('#ffffff', 0.1))
        // )

        return colorResolveLegibleForegroundColor(
            backgroundColor,
            assertNotNullable(colorBlend('#000000', backgroundColor, 0.05)),
            assertNotNullable(colorBlend('#ffffff', backgroundColor, 0.15))
        )
    }

    const mainColor = doppeColorPaletteResolveColor(colorPalette, DoppePaletteColorType.Main);
    const backgroundColor = doppeColorPaletteResolveColor(colorPalette, DoppePaletteColorType.Background);
    const textColor = doppeColorPaletteResolveColor(colorPalette, DoppePaletteColorType.Text);
    const secondaryColor = doppeColorPaletteResolveColor(colorPalette, DoppePaletteColorType.Secondary);
    const widgetBackgroundColor = doppeColorPaletteResolveColor(colorPalette, DoppePaletteColorType.WidgetBackground);
    const widgetTextColor = doppeColorPaletteResolveColor(colorPalette, DoppePaletteColorType.WidgetText);
    const widgetMainColor = doppeColorPaletteResolveColor(colorPalette, DoppePaletteColorType.WidgetMain);
    const widgetMainColorLegibleForeground = colorResolveLegibleForegroundColor(widgetMainColor);
    const inputBackgroundColor = doppeColorPaletteResolveColor(colorPalette, DoppePaletteColorType.InputBackground);
    const mainColorLegible = colorResolveLegibleForegroundColor(mainColor);
    const secondaryColorLegible = colorResolveLegibleForegroundColor(secondaryColor);
    const widgetBackgroundColorLegible = colorResolveLegibleForegroundColor(widgetBackgroundColor);
    const backgroundColorLegible = colorResolveLegibleForegroundColor(backgroundColor);
    const textLegibleBackgroundColor = colorResolveLegibleForegroundColor(textColor);

    const textFontFamilySpec = doppePageFontEnsureSupportedFontSpec(textFontFamily, lang);

    // TODO: Should be renamed to "desktopBackgroundColor"
    const whitenBackgroundColor = doppeColorPaletteResolveColor(colorPalette, DoppePaletteColorType.DesktopBackground);
    const whitenBackgroundColorLegibleText = colorResolveLegibleForegroundColor(whitenBackgroundColor);

    const popupBackgroundColor = doppeColorPaletteResolveColor(colorPalette, DoppePaletteColorType.PopupBackground); // '#fff';
    const popupMainColor = doppeColorPaletteResolveColor(colorPalette, DoppePaletteColorType.PopupMain); // '#116DFF'; /* #9271FF */
    const popupTextColor = doppeColorPaletteResolveColor(colorPalette, DoppePaletteColorType.PopupText); // '#333';
    const popupInputBackgroundColor = doppeColorPaletteResolveColor(colorPalette, DoppePaletteColorType.PopupInputBackground); // '#F2F2F2';
    const pageContentInputBackgroundColor = doppeColorPaletteResolveColor(colorPalette, DoppePaletteColorType.PageContentInputBackground); // '#fff';

    const customBymoPageContentWidthInWideLayout =
        doppeHideableValueIsVisible(devCustomPageContentWidthInWideLayout) &&
        stringIsNotNullableOrWhiteSpace(devCustomPageContentWidthInWideLayout) ?
            devCustomPageContentWidthInWideLayout :
            undefined;

    return {
        [bymoPageStyleVars.bymoPageContentWidthInWideLayout]: customBymoPageContentWidthInWideLayout ?? '500px',
        [bymoPageStyleVars.mainColor]: mainColor,
        [bymoPageStyleVars.mainColor10]: colorGetRgba(mainColor, 0.1) ?? mainColor,
        [bymoPageStyleVars.backgroundColor]: backgroundColor,
        [bymoPageStyleVars.pageContentInnerBackground]: resolveInnerBackgroundColor(backgroundColor),
        [bymoPageStyleVars.textLegibleBackgroundColor]: textLegibleBackgroundColor,
        [bymoPageStyleVars.whitenBackgroundColor]: whitenBackgroundColor,
        [bymoPageStyleVars.whitenBackgroundColorLegibleText]: whitenBackgroundColorLegibleText,
        [bymoPageStyleVars.backgroundColorRgb]: cssResolveRgbColorComponentsForFunctionalNotationFormat(backgroundColor) ?? `255 255 255`,
        [bymoPageStyleVars.mainTextColor]: textColor,
        [bymoPageStyleVars.skipButtonBackgroundColor]: secondaryColor,
        [bymoPageStyleVars.skipButtonTextColor]: secondaryColorLegible,
        [bymoPageStyleVars.separator]: assertNotNullable(backgroundColorLegible === 'black' ? colorGetRgba('#000000', 0.1) : colorGetRgba('#ffffff', 0.2)),
        [bymoPageStyleVars.actionButtonTextColor]: mainColorLegible,
        [bymoPageStyleVars.actionButtonTextColor80]: colorGetRgba(mainColorLegible, 0.8) ?? mainColorLegible,
        [bymoPageStyleVars.widgetBackgroundColor]: widgetBackgroundColor,
        [bymoPageStyleVars.widgetInnerSeparator]: assertNotNullable(widgetBackgroundColorLegible === 'black' ? colorGetRgba('#000000', 0.1) : colorGetRgba('#ffffff', 0.2)),
        [bymoPageStyleVars.widgetInnerBackground]: resolveInnerBackgroundColor(widgetBackgroundColor),
        [bymoPageStyleVars.widgetMainColor]: widgetMainColor,
        [bymoPageStyleVars.widgetMainColor60]: colorGetRgba(widgetMainColor, 0.6) ?? widgetMainColor,
        [bymoPageStyleVars.widgetMainColor80]: colorGetRgba(widgetMainColor, 0.8) ?? widgetMainColor,
        [bymoPageStyleVars.widgetMainColorLegibleForeground]: widgetMainColorLegibleForeground,
        [bymoPageStyleVars.widgetTextColor]: widgetTextColor,
        [bymoPageStyleVars.widgetTextColor60]: colorGetRgba(widgetTextColor, 0.6) ?? widgetTextColor,
        [bymoPageStyleVars.widgetTextColor80]: colorGetRgba(widgetTextColor, 0.8) ?? widgetTextColor,
        [bymoPageStyleVars.inputBackgroundColor]: inputBackgroundColor,
        [bymoPageStyleVars.inputBorderColor]: widgetTextColor,
        [bymoPageStyleVars.inputTextColor]: bymoPageStyleResolveInputTextColor(inputBackgroundColor),
        [bymoPageStyleVars.inputPlaceholderColor]: bymoPageStyleResolveInputPlaceholderColor(inputBackgroundColor),
        [bymoPageStyleVars.pageContentErrorColor]: colorResolveLegibleForegroundColor(backgroundColor, darkErrorColor, brightErrorColor),
        [bymoPageStyleVars.widgetErrorColor]: colorResolveLegibleForegroundColor(widgetBackgroundColor, darkErrorColor, brightErrorColor),
        [bymoPageStyleVars.doppeViewerColorPopupError]: colorResolveLegibleForegroundColor(popupBackgroundColor, darkErrorColor, brightErrorColor),
        [bymoPageStyleVars.pageContentSuccessColor]: colorResolveLegibleForegroundColor(backgroundColor, darkSuccessColor, brightSuccessColor),
        [bymoPageStyleVars.widgetSuccessColor]: colorResolveLegibleForegroundColor(widgetBackgroundColor, darkSuccessColor, brightSuccessColor),
        [bymoPageStyleVars.doppeViewerColorPopupSuccess]: colorResolveLegibleForegroundColor(popupBackgroundColor, darkSuccessColor, brightSuccessColor),
        [bymoPageStyleVars.topLevelInputBackgroundColor]: pageContentInputBackgroundColor,
        [bymoPageStyleVars.topLevelInputTextColor]: bymoPageStyleResolveInputTextColor(pageContentInputBackgroundColor),
        [bymoPageStyleVars.topLevelInputPlaceholderColor]: bymoPageStyleResolveInputPlaceholderColor(pageContentInputBackgroundColor),
        [bymoPageStyleVars.textFontFamily]: `'${textFontFamilySpec.fontFamily}', ${textFontFamilySpec.typefaceSpecification}`,
        [bymoPageStyleVars.textFontWeight]: textFontFamilySpec.textFontWeight,
        [bymoPageStyleVars.headingsFontWeight]: textFontFamilySpec.headingsFontWeight,
        /* Popup Colors */
        ...evaluateFunction(() => {


            const popupMainColor30 = colorWhiten(popupMainColor, 0.3) ?? popupMainColor;
            const popupMainColor10 = colorWhiten(popupMainColor, 0.1) ?? popupMainColor;
            const popupBackgroundColorLegible = colorResolveLegibleForegroundColor(popupBackgroundColor);

            return {
                [bymoPageStyleVars.doppeViewerColorPopupBackground]: popupBackgroundColor,
                [bymoPageStyleVars.doppeViewerColorPopupInnerBackground]: resolveInnerBackgroundColor(popupBackgroundColor),
                [bymoPageStyleVars.doppeViewerColorPopupText]: popupTextColor,
                [bymoPageStyleVars.doppeViewerColorPopupTextSecondary]: assertNotNullable(colorWhiten(popupTextColor, 0.65)), // Was #868AA5 when text color was #333
                [bymoPageStyleVars.doppeViewerColorPopupMain]: popupMainColor,
                [bymoPageStyleVars.doppeViewerColorPopupMain30]: popupMainColor30,
                [bymoPageStyleVars.doppeViewerColorPopupMain10]: popupMainColor10,
                [bymoPageStyleVars.doppeViewerColorPopupMainLegibleForeground]: colorResolveLegibleForegroundColor(popupMainColor, '#000', '#fff'),
                [bymoPageStyleVars.doppeViewerColorPopupMain30LegibleForeground]: colorResolveLegibleForegroundColor(popupMainColor30, '#000', '#fff'),
                [bymoPageStyleVars.doppeViewerColorPopupMain10LegibleForeground]: colorResolveLegibleForegroundColor(popupMainColor10, '#000', '#fff'),
                [bymoPageStyleVars.doppeViewerColorPopupInnerSeparator]: assertNotNullable(popupBackgroundColorLegible === 'black' ? colorGetRgba('#000000', 0.1) : colorGetRgba('#ffffff', 0.2)),
                [bymoPageStyleVars.doppeViewerColorPopupInputBackgroundColor]: popupInputBackgroundColor,
                [bymoPageStyleVars.doppeViewerColorPopupInputBorderColor]: popupInputBackgroundColor,
                [bymoPageStyleVars.doppeViewerColorPopupInputTextColor]: bymoPageStyleResolveInputTextColor(popupInputBackgroundColor),
                [bymoPageStyleVars.doppeViewerColorPopupInputPlaceholderColor]: bymoPageStyleResolveInputPlaceholderColor(popupInputBackgroundColor),
            }
        }),
    }

}

export const BymoPageLayout = {
    Narrow: 'narrow',
    Wide: 'wide'
} as const;

export type BymoPageLayout = Values<typeof BymoPageLayout>;

export const BymoPageLayoutSizes = {
    WideLayoutContentWidth: 500,
    WideLayoutMinWidth: 750
} as const;

export type BymoPageLayoutSizes = Values<typeof BymoPageLayoutSizes>;

export function useBymoPageLayout() {

    const rootWindow = useRootWindow();

    const windowWidth = useLayoutBinding(
        useCallback(() => (rootWindow ?? window).innerWidth, [rootWindow]),
        referencesEqualityComparer
    )

    return windowWidth !== null ? bymoPageLayoutResolveFromViewportWidth(windowWidth) : BymoPageLayout.Wide;
}

export function bymoPageLayoutResolveFromViewportWidth(viewportWidth: number) {
    if (viewportWidth < BymoPageLayoutSizes.WideLayoutMinWidth) {
        return BymoPageLayout.Narrow;
    } else {
        return BymoPageLayout.Wide;
    }
}

export const BymoPageStyleScope = {
    Popup: 'popup',
    Document: 'document',
    PageContent: 'pageContent',
    Card: 'card'
} as const;

export type BymoPageStyleScope = Values<typeof BymoPageStyleScope>;

export const BymoPageStyleScopeContext = React.createContext<BymoPageStyleScope>(BymoPageStyleScope.Document);

export function useBymoPageStyleScope () {
    return useContext(BymoPageStyleScopeContext)
}

export function bymoPageStyleGetStyleScopeClassName (styleScope: BymoPageStyleScope) {
    switch (styleScope) {
        case BymoPageStyleScope.PageContent: {
            return classes.pageContentScope
        }
        case BymoPageStyleScope.Card: {
            return classes.cardScope;
        }
        case BymoPageStyleScope.Popup: {
            return classes.popupScope
        }
        case BymoPageStyleScope.Document: {
            return classes.documentScope
        }
    }
}

export const BymoPageStyleContext = React.createContext<Record<string, string> | null>(null);

export function useBymoPageStyleVarValue (varName: BymoPageStyleVarsNames) {
    const bymoPageStyle = assertNotNullable(useContext(BymoPageStyleContext), `BymoPageStyleContext isn't provided`);

    return assertNotNullable(bymoPageStyle[bymoPageStyleVars[varName]])
}

export function bymoPageStyleResolveVarNameInScope (styleScope: BymoPageStyleScope, scopeVarName: BymoPageStyleScopeColors) : BymoPageStyleVarsNames {

    switch (scopeVarName) {
        case BymoPageStyleScopeColors.InnerBackgroundColor: {
            switch (styleScope) {
                case BymoPageStyleScope.Card: {
                    return BymoPageStyleVarsNames.widgetInnerBackground;
                }
                case BymoPageStyleScope.Popup: {
                    return BymoPageStyleVarsNames.doppeViewerColorPopupInnerBackground;
                }
                case BymoPageStyleScope.PageContent:
                case BymoPageStyleScope.Document: {
                    return BymoPageStyleVarsNames.pageContentInnerBackground;
                }
            }
            break;
        }
        case BymoPageStyleScopeColors.TextColor: {
            switch (styleScope) {
                case BymoPageStyleScope.Card: {
                    return BymoPageStyleVarsNames.widgetTextColor;
                }
                case BymoPageStyleScope.Popup: {
                    return BymoPageStyleVarsNames.doppeViewerColorPopupText;
                }
                case BymoPageStyleScope.PageContent:
                case BymoPageStyleScope.Document: {
                    return BymoPageStyleVarsNames.mainTextColor;
                }
            }
            break;
        }
        case BymoPageStyleScopeColors.BackgroundColor: {
            switch (styleScope) {
                case BymoPageStyleScope.Card: {
                    return BymoPageStyleVarsNames.widgetBackgroundColor;
                }
                case BymoPageStyleScope.Popup: {
                    return BymoPageStyleVarsNames.doppeViewerColorPopupBackground;
                }
                case BymoPageStyleScope.PageContent:
                case BymoPageStyleScope.Document: {
                    return BymoPageStyleVarsNames.backgroundColor;
                }
            }
            break;
        }
        case BymoPageStyleScopeColors.MainColor: {
            switch (styleScope) {
                case BymoPageStyleScope.Card: {
                    return BymoPageStyleVarsNames.widgetMainColor;
                }
                case BymoPageStyleScope.Popup: {
                    return BymoPageStyleVarsNames.doppeViewerColorPopupMain;
                }
                case BymoPageStyleScope.PageContent:
                case BymoPageStyleScope.Document: {
                    return BymoPageStyleVarsNames.mainColor;
                }
            }
            break;
        }
        // case BymoPageStyleScopeVarsNames.bymoPageStyleScopeMainColorLegibleForeground: {
        //
        // }
        case BymoPageStyleScopeColors.ErrorTextColor: {
            switch (styleScope) {
                case BymoPageStyleScope.Card: {
                    return BymoPageStyleVarsNames.widgetErrorColor;
                }
                case BymoPageStyleScope.Popup: {
                    return BymoPageStyleVarsNames.doppeViewerColorPopupError;
                }
                // The input background in the page content is currently equal to the widget background color.
                case BymoPageStyleScope.PageContent:
                case BymoPageStyleScope.Document: {
                    return BymoPageStyleVarsNames.pageContentErrorColor;
                }
            }
            break;
        }
        case BymoPageStyleScopeColors.SuccessTextColor: {
            switch (styleScope) {
                case BymoPageStyleScope.Card: {
                    return BymoPageStyleVarsNames.widgetSuccessColor;
                }
                case BymoPageStyleScope.Popup: {
                    return BymoPageStyleVarsNames.doppeViewerColorPopupSuccess;
                }
                // The input background in the page content is currently equal to the widget background color.
                case BymoPageStyleScope.PageContent:
                case BymoPageStyleScope.Document: {
                    return BymoPageStyleVarsNames.pageContentSuccessColor;
                }
            }
            break;
        }
        case BymoPageStyleScopeColors.InputFieldBackgroundColor: {
            switch (styleScope) {
                case BymoPageStyleScope.Card: {
                    return BymoPageStyleVarsNames.inputBackgroundColor;
                }
                case BymoPageStyleScope.Popup: {
                    return BymoPageStyleVarsNames.doppeViewerColorPopupInputBackgroundColor;
                }
                // The input background in the page content is currently equal to the widget background color.
                case BymoPageStyleScope.PageContent:
                case BymoPageStyleScope.Document: {
                    return BymoPageStyleVarsNames.topLevelInputBackgroundColor;
                }
            }
            break;
        }
        // case BymoPageStyleScopeVarsNames.bymoPageStyleScopeInputBorderColor: {
        //
        // }
        // case BymoPageStyleScopeVarsNames.bymoPageStyleScopeInputTextColor: {
        //
        // }
        // case BymoPageStyleScopeVarsNames.bymoPageStyleScopeInputPlaceholderColor: {
        //
        // }
    }
}

export function useBymoPageScopeColor (bymoPageColor: BymoPageStyleScopeColors, specificScope?: BymoPageStyleScope) {

    const bymoPageStyle = assertNotNullable(useContext(BymoPageStyleContext), `BymoPageStyleContext isn't provided`);
    const bymoPageStyleScope = useBymoPageStyleScope();

    return useMemo(() => {
        return bymoPageStyle[bymoPageStyleVars[
            bymoPageStyleResolveVarNameInScope(specificScope ?? bymoPageStyleScope, bymoPageColor)
        ]];
    }, [bymoPageColor, bymoPageStyle, bymoPageStyleScope, specificScope])
}

export function bymoPageStyleResolveInputTextColor (inputBackgroundColor: ColorValue) {
    // return colorResolveLegibleForegroundColor(inputBackgroundColor, '#20303C', '#ffffff')
    return colorResolveLegibleForegroundColor(inputBackgroundColor, '#000000', '#ffffff')
}

export function bymoPageStyleResolveInputPlaceholderColor (inputBackgroundColor: ColorValue) {
    // return colorResolveLegibleForegroundColor(inputBackgroundColor, '#6B6B6B', assertNotNullable(colorGetRgba('#ffffff', 0.55)))

    return colorResolveLegibleForegroundColor(inputBackgroundColor, 'rgba(0,0,0,0.5)', 'rgba(255,255,255,0.7)')
}