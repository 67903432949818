import React, {useEffect} from "react";
import {EventEmitter, IObservable} from "@wix/devzai-utils-common";
import {useForceUpdate} from "../hooks";


export type ObservableRenderFunction = {
    render: () => React.ReactElement;
    dependencies: ObservableRenderFunction.Dependency[];
}

export namespace ObservableRenderFunction {
    export type Dependency = IObservable;
}

export function useObservableRenderFunction (
    observableRenderFunction: ObservableRenderFunction | React.ReactElement
) : React.ReactElement {

    const forceUpdate = useForceUpdate();

    const dependencies = React.isValidElement(observableRenderFunction) ? [] : observableRenderFunction.dependencies;

    useEffect(() => {

        const bindings = dependencies.map((dep) => EventEmitter.on(dep.eventUpdated, () => {
            forceUpdate();
        }))

        return () => {
            for (const binding of bindings) {
                binding.dispose();
            }
        }
    }, [dependencies, forceUpdate])

    return React.isValidElement(observableRenderFunction) ? observableRenderFunction : observableRenderFunction.render()

}