import {
    doppeActionTypeCreateSettingsResolvingFunction,
    doppeActionTypeDefineMetadata
} from '../../client-server-common/doppe-action-types/doppe-action-type';


export namespace DoppeActionTypeSendEmail {

    export interface ActionSettings {
        email: string;
    }
}

export const doppeActionTypeSendEmail = doppeActionTypeDefineMetadata<DoppeActionTypeSendEmail.ActionSettings>({
    id: 'io.bymo.action/send-email',
    resolveActionSettings: doppeActionTypeCreateSettingsResolvingFunction({
        email: '',
    })
})