import React, {useImperativeHandle, useRef} from "react";
import {evaluateWhenFunction} from "@wix/devzai-utils-common";
import {IInnerWindowClosingController} from '../windows/inner-window'
import {DomElementAnimator} from "../dom-element-animator/dom-element-animator";

export const InnerWindowAnimator = React.memo(function InnerWindowAnimator (props: InnerWindowAnimator.Props) {
    const {
        children,
        windowClosingControllerRef,
        openTransition,
        closeTransition
    } = props;

    const elementAnimatorRef = useRef<DomElementAnimator>(null);

    useImperativeHandle(windowClosingControllerRef, () => {
        return {
            handleWindowClosing(closeCallback: () => void) {

                const elementAnimator = elementAnimatorRef.current;
                if (elementAnimator && closeTransition !== undefined) {

                    elementAnimator.animate(element => {
                        const closeTransitionSpec = evaluateWhenFunction(closeTransition, element);

                        if (!closeTransitionSpec) {
                            closeCallback();

                            return undefined;
                        } else {
                            return {
                                ...closeTransitionSpec,
                                onAnimationEnd: (element, finished) => {
                                    closeCallback();
                                    closeTransitionSpec.onAnimationEnd?.(element, finished);
                                }
                            }
                        }
                    })

                } else {
                    closeCallback();
                }
            }
        }
    })

    return (
        <DomElementAnimator
            ref={elementAnimatorRef}
            autoAnimateOnMount={openTransition}
        >
            {children}
        </DomElementAnimator>
    )
});

export namespace InnerWindowAnimator {
    export interface Props {
        windowClosingControllerRef: React.Ref<IInnerWindowClosingController>;
        children: React.ReactElement;
        openTransition?: DomElementAnimator.EvaluableAnimationSpec;
        closeTransition?: DomElementAnimator.EvaluableAnimationSpec;
    }
}