import {
    doppeActionTypeCreateSettingsResolvingFunction,
    doppeActionTypeDefineMetadata
} from '../../client-server-common/doppe-action-types/doppe-action-type';

export namespace DoppeActionTypeFBMessengerButton {

    export interface ActionSettings {
        userName: string;
        showContactPanel: boolean
    }
}

export const doppeActionTypeFbMessengerButton = doppeActionTypeDefineMetadata<DoppeActionTypeFBMessengerButton.ActionSettings>({
    id: 'io.bymo.action/fb-messenger-button',
    resolveActionSettings: doppeActionTypeCreateSettingsResolvingFunction({
        userName: '',
        showContactPanel: false,
    }),
});
