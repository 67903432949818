import { iterableMapToArray } from './iterable-utils/iterable-utils';
import {regexEscapeRegExpString, regexExec} from "./regex-utils/regex-utils";
import {ArrayOrSingle} from "./common-types";
import {asArray} from "./array-utils";

export function stringTruncate (str: string, length: number) {
    return str.substring(0, length);
}

export function stringReplaceEmptyValue<T> (str: undefined, emptyValue: T) : T;
export function stringReplaceEmptyValue<T> (str: '', emptyValue: T) : T;
export function stringReplaceEmptyValue<T> (str: string, emptyValue: T) : string | T;
export function stringReplaceEmptyValue<T> (str: string | undefined, emptyValue: T) : string | T
export function stringReplaceEmptyValue<T> (str: string | undefined, emptyValue: T) : string | T {
    return (str === undefined || str === '') ? emptyValue : str;
}

export function stringReplaceEmptyOrWhiteSpaceValue<T> (str: undefined, emptyValue: T) : T;
export function stringReplaceEmptyOrWhiteSpaceValue<T> (str: '', emptyValue: T) : T;
export function stringReplaceEmptyOrWhiteSpaceValue<T> (str: string, emptyValue: T) : string | T;
export function stringReplaceEmptyOrWhiteSpaceValue<T> (str: string | undefined, emptyValue: T) : string | T
export function stringReplaceEmptyOrWhiteSpaceValue<T> (str: string | undefined, emptyValue: T) : string | T {
    return (str === undefined || stringIsWhiteSpace(str)) ? emptyValue : str;
}

export function stringReplaceWhenEqual (str: string, value: string, replacedValue: string) {
    return str === value ? replacedValue : str;
}

export function stringIsNotNullableOrWhiteSpace (str: string | undefined | null) : str is string {
    return str !== undefined && str !== null && !stringIsWhiteSpace(str);
}

export function stringIsWhiteSpace (str: string) {
    return str.trim() === '';
}

export function stringIsNullableOrWhiteSpace (str: string | undefined | null) {
    return !stringIsNotNullableOrWhiteSpace(str);
}

export function stringTrimWhiteSpaces (str: string) {
    return str.trim();
}

export function stringReplaceSubstring (str: string, startingIndex: number, subString: string) {
    return str.substring(0, startingIndex) + subString + str.substring(startingIndex + subString.length)
}

export function stringTruncateWithEllipsis (str: string, length: number, ellipsis = '...') {

    if (str.length > length) {
        return str.substring(0, length - ellipsis.length) + ellipsis;
    } else {
        return str;
    }
}

export function stringReverse (str: string) {
    return str.split('').reverse().join('');
}

export function stringGetNumberOfWords (str: string) {

    if (stringIsWhiteSpace(str)) {
        return 0;
    } else {
        return stringTrimWhiteSpaces(str).split(/\s+/).length;
    }
}

export function stringSplitBySuffixWordsCount (str: string, wordsCount: number) : [string, string] {
    const totalNumberOfWords = stringGetNumberOfWords(str);
    return stringSplitByWordsCount(str, Math.max(totalNumberOfWords - wordsCount, 0))
}

export function stringSplitByWordsCount (str: string, wordsCount: number) : [string, string] {
    if (/^\s*$/.test(str)) {
        return [str, ''];
    } else if (wordsCount > 0) {

        const regexp = new RegExp(`^((\\s*\\S+\\s*){${wordsCount}})(.*)$`, 's');

        const match = regexExec(regexp, str);

        return [match?.[1] ?? '', match?.[3] ?? ''];
    } else {
        const match = regexExec(/^(\s*)(.*)$/s, str);

        return [match?.[1] ?? '', match?.[2] ?? ''];
    }
}

export function stringExtractWords (str: string, numberOfWords: number) {
    return stringCollapseWhitespaces(str).split(/\s/).slice(0, numberOfWords);
}

export function stringTrim (str: string, efix: string) {
    return stringTrimSuffix(stringTrimPrefix(str, efix), efix);
}

export function stringTrimSuffix(str: string, suffix: string) {
    const suffixLength = suffix.length;

    if (suffixLength > 0) {
        const strLength = str.length;

        if (suffixLength <= strLength) {
            const suffixStartIndex = strLength - suffixLength;
            const actualSuffix = str.substring(suffixStartIndex);
            if (actualSuffix === suffix) {
                return str.substring(0, suffixStartIndex);
            }
        }
    }

    return str;
}

export function stringEnsureSuffix(str: string, suffix: string) {

    if (str.endsWith(suffix)) {
        return str;
    } else {
        return str + suffix;
    }
}

export function stringEnsurePrefix(str: string, prefix: string) {

    if (str.startsWith(prefix)) {
        return str;
    } else {
        return prefix + str;
    }
}

export function stringTrimPrefix(str: string, prefix: string) {
    const prefixLength = prefix.length;

    if (prefixLength > 0) {
        const strLength = str.length;

        if (prefixLength <= strLength) {
            const actualPrefix = str.substring(0, prefixLength);
            if (actualPrefix === prefix) {
                return str.substring(prefixLength);
            }
        }
    }

    return str;
}

export function stringElipsisFromStartByLength(str: string, maxLength: number) {
    const strLength = str.length;

    if (strLength > maxLength) {
        return '...' + str.slice(strLength-maxLength + 3, strLength);
    }

    return str;
}

export function stringUnwrap(str: string, affix: string) : string;
export function stringUnwrap(str: string, prefix: string, suffix: string) : string;
export function stringUnwrap(str: string, prefixOrAffix: string, suffixOrNone?: string) : string {

    const prefix = prefixOrAffix;
    const suffix = suffixOrNone !== undefined ? suffixOrNone : prefixOrAffix;

    if (str.startsWith(prefix) && str.endsWith(suffix)) {
        return str.substring(prefix.length, str.length - prefix.length - suffix.length + 1);
    } else {
        return str;
    }
}

export function stringGenerateValidString(
    value: string,
    validator: (value: string) => boolean,
    valueGenerator: (iterationNumber: number, value: string) => string
) {
    let currentValue = value;
    let iterationNumber = 0;

    while (!validator(currentValue)) {
        currentValue = valueGenerator(iterationNumber, value);
        iterationNumber++;
    }

    return currentValue;
}

export function stringGenerateUnique(
    value: string,
    existingValues: Iterable<string>,
    valueGenerator: (iterationNumber: number, value: string) => string,
    caseInsensitive = false
) {
    let currentValue = value;
    let iterationNumber = 0;

    const existingValuesSet = new Set(
        caseInsensitive ? iterableMapToArray(existingValues, value => value.toLowerCase()) : existingValues
    );

    while (existingValuesSet.has(caseInsensitive ? currentValue.toLowerCase() : currentValue)) {
        currentValue = valueGenerator(iterationNumber, value);
        iterationNumber++;
    }

    return currentValue;
}

export function stringConvertJsIdentifierToDashCase(str: string) {
    return str
        .replace(/([A-Z])([A-Z]+)([A-Z][^$])/g, (_, g1, g2, g3) => ' ' + g1 + g2.toLowerCase() + ' ' + g3)
        .replace(/([A-Z])([A-Z]+)$/g, (_, g1, g2) => ' ' + g1 + g2.toLowerCase())
        .replace(/([^\s])([A-Z])/g, (_, g1, g2) => g1 + ' ' + g2)
        .trim()
        .replace(/\s/g, '-')
        .toLowerCase();
}

export function stringCollapseWhitespaces(str: string) {
    return str.replace(/\s+/g, ' ');
}

export function stringRemoveWhitespaces(str: string) {
    return str.replace(/\s+/g, '');
}

export function stringIndexOfOccurrence(str: string, searchStr: string, occurrenceIndex = 0) {
    let cursor = 0;
    let result = -1;

    for (let i = 0; i <= occurrenceIndex; i++) {
        result = str.indexOf(searchStr, cursor);
        if (result === -1) {
            return -1;
        } else {
            cursor = result + searchStr.length;
        }
    }

    return result;
}

export function stringReplaceAll (
    str: string,
    replacements: ArrayOrSingle<{from: string; to: string}>
) {
    const replacementsArr = asArray(replacements);
    const replacementsMap = new Map(replacementsArr.map(replacement => [replacement.from, replacement.to]));

    const replacementRegExpStr = replacementsArr.map(replacement => regexEscapeRegExpString(replacement.from)).join('|');

    return str.replace(new RegExp(replacementRegExpStr, 'g'), (match) => {
        return replacementsMap.get(match) ?? ''
    })
}

export function stringUpperCaseFirstLetter (str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function stringLowerCaseFirstLetter (str: string) {
    return str.charAt(0).toLowerCase() + str.slice(1);
}

export function stringDashCaseToCamelCase (str: string) {
    return stringLowerCaseFirstLetter(str.replace(/-+([a-z]|$)/g, function (_, m) { return m.toUpperCase(); }));
}

export function stringSnakeCaseToCamelCase (str: string) {
    return stringLowerCaseFirstLetter(str.replace(/_+([a-z]|$)/g, function (_, m) { return m.toUpperCase(); }));
}

export function stringDashCaseToPascalCase (str: string) {
    return stringUpperCaseFirstLetter(stringDashCaseToCamelCase(str));
}

export function stringSnakeCaseToPascalCase (str: string) {
    return stringUpperCaseFirstLetter(stringSnakeCaseToCamelCase(str));
}

export function stringSquashStringsArray (strArr: string[], maxItemLength: number, separator = '') {
    const squashedArr: string[] = [];

    for (const str of strArr) {

        if (squashedArr.length === 0 || squashedArr[squashedArr.length - 1].length + str.length + separator.length > maxItemLength) {
            squashedArr.push(str);
        } else {
            squashedArr[squashedArr.length - 1] += (separator + str);
        }
    }

    return squashedArr;
}

export function stringSearchFromEnd (str: string, searchStr: string, occurrenceIndex = 0) {

    let prevOccurrencePos = str.length;

    for (let currentOccurrenceIndex = 0; currentOccurrenceIndex <= occurrenceIndex && prevOccurrencePos >= 0; currentOccurrenceIndex++) {
        prevOccurrencePos = str.lastIndexOf(searchStr, prevOccurrencePos - 1);
    }

    return prevOccurrencePos;
}

export function stringExtractSubstringUntilMatch (str: string, searchStr: string) {
    const searchStrIndex = str.indexOf(searchStr);

    if (searchStrIndex >= 0) {
        return str.substring(0, searchStrIndex);
    } else {
        return str;
    }
}

export function stringExtractSubstringFromMatch (str: string, searchStr: string) {
    const searchStrIndex = str.indexOf(searchStr);

    if (searchStrIndex >= 0) {
        return str.substring(searchStrIndex);
    } else {
        return str;
    }
}

export function stringExtractSubstringFromLastMatch (str: string, searchStr: string) {
    const searchStrIndex = str.lastIndexOf(searchStr);

    if (searchStrIndex >= 0) {
        return str.substring(searchStrIndex);
    } else {
        return '';
    }
}

export function stringExtractSubstringFromMatchEnd (str: string, searchStr: string) {
    const searchStrIndex = str.indexOf(searchStr);

    if (searchStrIndex >= 0) {
        return str.substring(searchStrIndex + searchStr.length);
    } else {
        return str;
    }
}

export function stringSplitAtFirstMatch (str: string, searchStr: string, excludeMatch = false) : [string, string] | undefined {
    const searchStrIndex = str.indexOf(searchStr);

    if (searchStrIndex >= 0) {

        if (excludeMatch) {
            return [str.substring(0, searchStrIndex), str.substring(searchStrIndex + searchStr.length)];
        } else {
            return [str.substring(0, searchStrIndex), str.substring(searchStrIndex)];
        }

    } else {
        return undefined;
    }
}

export function stringSplitAtLastMatch (str: string, searchStr: string, excludeMatch = false) : [string, string] | undefined {
    const searchStrIndex = str.lastIndexOf(searchStr);

    if (searchStrIndex >= 0) {

        if (excludeMatch) {
            return [str.substring(0, searchStrIndex), str.substring(searchStrIndex + searchStr.length)];
        } else {
            return [str.substring(0, searchStrIndex), str.substring(searchStrIndex)];
        }

    } else {
        return undefined;
    }
}

export function stringSplitAtPosition (str: string, position: number) : [string, string] {
    return [str.substring(0, position), str.substring(position)];
}

export function stringReplaceParams(str: string, params: { [key: string]: string }): string {
    for (const key of Object.keys(params)) {
        // Create a regular expression to match the key wrapped in curly brackets
        const regex = new RegExp(`(?<!\\{)\\{${key}\\}(?!\\})`, 'g');

        // Replace all occurrences of the key in the string with its corresponding value
        str = str.replace(regex, params[key]);
    }

    return str;
}
